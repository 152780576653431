import { getOrganizationsByIdRequest } from "@/helpers/api/organizations";
import { getObjectByIdRequest } from "@/helpers/api/objects";
import { getControllerByIdRequest } from "@/helpers/api/controllers";
import { getAccessGroupByIdRequest } from "@/helpers/api/access-group";
import {
  getUserByIdRequest,
  getUserKindergartensByIdRequest
} from "@/helpers/api/user";
import { getPaymentByIdRequest } from "@/helpers/api/payments";
import { getServiceGroupByIdRequest } from "@/helpers/api/service-groups";
import { getKindergartenByIdRequest } from "@/helpers/api/kindergartens";
import { getChildrenGroupByIdRequest } from "@/helpers/api/childrenGroup";
import { getChildrenByRequest } from "@/helpers/api/Children/Children";
import { getServiceTaskByIdRequest } from "@/helpers/api/serviceTasks";
import router from "@/router";
import { getCardByIdRequest } from "@/helpers/api/cards";
import { getIdentificatorUserRole } from "../Constants/roles";

export function routeName(route) {
  switch (route) {
    case "dealer":
      return "Дилеры";
    case "company":
      return "Компании";
    case "objects":
      return "Объекты";
    case "controllers":
      return "Контроллеры";
    case "userGroups":
      return "Группы пользователей";
    case "users":
      return "Пользователи";
    case "usersKindergarten":
      return "Пользователи";
    case "payment":
      return "Платежи";
    case "serviceGroups":
      return "Группы объектов";
    case "kindergartens":
      return "Детские сады";
    case "cards":
      return "Карты";
    case "accounts":
      return "Лицевые счета";
    case "childrenGroups":
      return "Детские группы";
    case "serviceOrganization":
      return "Обслуживающие организации";
    case "children":
      return "Воспитанники";
    case "ServiceTask":
      return "Заявки";
    case "createDealer":
      return "Добавление дилера";
    case "generalInformation":
      return "Общая информация";
    case "personnel":
      return "Персонал";
    case "notification":
      return "Уведомления";
    case "createUserGroup":
      return "Добавление группы пользователь";
    case "createObject":
      return "Добавление объекта";
    case "createServiceGroup":
      return "Добавление группы объектов";
    case "createKindergartens":
      return "Добавление детского сада";
    case "createCardPage":
      return "Добавление карт";
    case "createAccountPage":
      return "Добавление лицевых счетов";
    case "createUser":
      return "Добавление пользователей";
    case "createUserObjects":
      return "Добавление пользователей";
    case "createServiceOrganization":
      return "Добавление обслуживающей организации";
    case "createServiceTaskPage":
      return "Добавление заявки";
    case "passageLog":
      return "Журнал проходов";
    case "createCompany":
      return "Добавление компании";
    case "childrenVisitLogPage":
      return "Табель посещаемости";
    case "parentsCreate":
      return "Добавление опекуна";
    case "admin":
      return "Администратор";
    case "createAdmin":
      return "Добавление администратора";
    case "PersonnelCreate":
      return "Добавление персонала";
    case "createStaff":
      return "Создание персонала";
    case "manageressCreate":
      return "Создание воспитателя";
    case "staff":
      return "Персонал";
    case "PersonnelCheck":
      return "Контроль персонала";
    case "companyEdit":
      return "Изменение комании";
    case "objectsEdit":
      return "Изменение объекта";
    case "editServiceGroup":
      return "Изменение группы объектов";
    case "personnelControl":
      return "Контроль персонала";
    case "AboutCompany":
      return "О компании";
    case "AboutDiller":
      return "О дилере";
    case "controllersEdit":
      return "Изменение контроллера";
    case "userGroupsEdit":
      return "Изменение группы пользователей";
    case "usersEdit":
      return "Изменение пользователя";
    case "usersObjectsEdit":
      return "Изменение пользователя";
    case "createController":
      return "Создание контроллера";
    case "WorkingTimeSetting":
      return "Настройка рабочего времени";
    case "parentInfos":
      return "Опекун";
    case "editParent":
      return "Изменение опекуна";
    case "childrenGroupEdit":
      return "Изменение детской группы";
    case "createChildren":
      return "Создание воспитанника";
    case "childrenGroupsCreate":
      return "Добавление детской группы";
    case "personnelCreate":
      return "Добавление сотрудника";
    case "personnelEdit":
      return "Изменение персонала";
    case "editServiceTask":
      return "Изменения заявки";
    case "childrenEdit":
      return "Изменение воспитанника";
    case "profile":
      return "Профиль";
    case "myObjects":
      return "Мои объекты";
    case "payments":
      return "Платежи";
    case "firmwares":
      return "Прошивки";
    case "firmwareEdit":
      return "Редактирование прошивки";
    case "createFirmware":
      return "Добавление прошивки";
    case "kindergartenEdit":
      return "Изменение детского сада";
    case "adminsOppen":
      return "Администраторы Oppen";
    case "createAdminOppen":
      return "Добавление администратора Oppen";
    case "widgets":
      return "Статистика";
    case "review":
      return "Отзывы";
    case "promocodes":
      return "Промокоды";
    case "createPromocodes":
      return "Промокоды";
    case "codes":
      return "Коды аутентификации";
    case "access":
      return "Реестр доступов";
    case "usersGeneralEdit":
      return "Изменение пользователя";
    default:
      return route;
  }
}
export async function routeIdName(id, route) {
  switch (route) {
    case "parentInfos": {
      const user = (await getUserByIdRequest({ id: id })).data;
      return user?.phone_number || "";
    }
    case "dealer":
      return (await getOrganizationsByIdRequest({ id: id })).data.name;
    case "company":
      return (await getOrganizationsByIdRequest({ id: id })).data.name;
    case "objects":
      return (await getObjectByIdRequest({ id: id })).data.name;
    case "kindergartenEdit":
      return (await getKindergartenByIdRequest({ id: id })).data.name;
    case "controllers":
      return (await getControllerByIdRequest({ id: id })).data.name;
    case "card":
      return (await getCardByIdRequest({ id: id })).data.code;
    case "userGroups":
      return (await getAccessGroupByIdRequest({ id: id })).data.title;
    case "usersObject": {
      const user = (await getUserByIdRequest({ id: id })).data;
      return user?.phone_number || "";
    }
    case "admin": {
      const user = (await getUserByIdRequest({ id: id })).data;
      return user?.phone_number || "";
    }
    case "users": {
      const user = (await getUserByIdRequest({ id: id })).data;
      return user?.phone_number || "-";
    }
    case "payment":
      return (await getPaymentByIdRequest({ id: id })).data.id;
    case "serviceGroups": {
      const serviceGroup = (await getServiceGroupByIdRequest({ id: id })).data;

      return serviceGroup.name
        ? serviceGroup.name
        : `Группа объектов ${serviceGroup.id}`;
    }
    case "kindergartens":
      // return (await getKindergartensRequest()).data.name;
      return (await getKindergartenByIdRequest({ id: id })).data.name;
    case "childrenGroups":
      return (await getChildrenGroupByIdRequest({ id: id })).data.name;
    case "children": {
      const user = (await getChildrenByRequest({ id: id })).data;
      return user?.name || "";
    }
    case "profile":
      return "Изменение профиля";
    case "staff": {
      const user = (await getUserByIdRequest({ id: id })).data;
      return user?.phone_number || "";
    }
    case "personnel": {
      const user = (await getUserByIdRequest({ id: id })).data;
      return user?.phone_number || "";
    }
    case "serviceOrganization":
      return (await getOrganizationsByIdRequest({ id: id })).data.name;
    case "ServiceTask":
      return (await getServiceTaskByIdRequest({ id })).data.title;
    case "companyEdit":
      return (await getOrganizationsByIdRequest({ id: id })).data.name;
    case "objectsEdit":
      return (await getObjectByIdRequest({ id: id })).data.name;
    case "controllersEdit":
      return (await getControllerByIdRequest({ id: id })).data.name;
    case "userGroupsEdit":
      return (await getAccessGroupByIdRequest({ id: id })).data.title;
    case "usersEdit": {
      const user = (
        await getUserKindergartensByIdRequest({
          kindergardens_id: getIdentificatorUserRole(),
          user_id: id
        })
      ).data;
      return user?.phone_number;
    }
    case "usersGeneralEdit": {
      const user = (await getUserByIdRequest({ id: id })).data;
      return user?.phone_number || "";
    }
    case "usersObjectsEdit": {
      const user = (await getUserByIdRequest({ id: id })).data;
      return user?.phone_number || "";
    }
    case "childrenGroupEdit":
      return (await getChildrenGroupByIdRequest({ id: id })).data.name;
    case "childrenEdit": {
      const user = (await getChildrenByRequest({ id: id })).data;
      return user?.first_name || "";
    }
    case "editParent": {
      const user = (await getUserByIdRequest({ id: id })).data;
      return user?.phone_number || "";
    }
    case "personnelEdit": {
      const user = (await getUserByIdRequest({ id: id })).data;
      return user?.phone_number || "";
    }
    case "editServiceTask":
      return (await getServiceTaskByIdRequest({ id })).data.title;
    case "firmwares":
      return "ID " + router.history.current.params.id;
    case "firmwareEdit":
      return "ID " + router.history.current.params.id;
    default:
      return routeName(route);
  }
}
