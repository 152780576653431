import moment from "moment";

export function getCardCodeId(arr) {
  return arr?.reduce(function(accum, current) {
    accum.push(current.id);
    return accum;
  }, []);
}

export function checkSameParent(arr, parentId) {
  let response = false;
  arr.forEach(function(elem) {
    if (elem.parents.includes(Number(parentId))) {
      response = true;
    }
  });
  return response;
}

export function checkIsSameGroup(arr, accessGroup) {
  let response = false;
  arr.forEach(function(elem) {
    if (elem.id == accessGroup) {
      response = true;
    }
  });
  return response;
}

export function lastActualPayments(obj) {
  const { arr, groupId } = obj;
  const completed_payments = arr.filter(el => el.group == groupId);
  if (completed_payments.length > 0) {
    let last_el = completed_payments.pop();
    return `${moment(last_el.date_end * 1000).format("DD.MM.YYYY")}`;
  } else {
    return "-";
  }
}
