<template>
  <div>
    <div class="object__item d-flex justify-end">
      <div class="object__block-rate " style="width: 100%">
        <div class="flex-ver-center" v-if="app">
          <img
            src="/img/icons/object__icon-phone.svg"
            alt="object__icon-phone"
            class="object__icon-phone"
            style="height: 65px; width: 50px"
          />
          <div style="width: 100%">
            <p class="object__text-application">Доступ по приложению</p>
            <div class="flex-ver-center"></div>

            <div v-if="app" class=" flex-ver-center">
              <div class="d-flex flex-column">
                <div style="width: 100%" class="d-flex text_block">
                  <p
                    class="object__text-term"
                    :class="{ 'mt-2 mb-2': data.app_access_type === 'unpaid' }"
                  >
                    <template v-if="data.app_access_type === 'paid'">
                      Оплаченный доступ:<span class="ml-3"
                        >{{ app.app_days_left }} дней</span
                      >
                    </template>
                    <template v-else-if="data.app_access_type === 'unpaid'">
                      Бесплатный доступ
                    </template>
                    <template v-else>
                      Пробный доступ:
                      <span class="ml-9">{{ app.app_days_left }} дней</span>
                    </template>
                  </p>

                  <div class="object__text">
                    <p
                      v-if="data.app_access_type != 'unpaid'"
                      class="object__text-date"
                    >
                      с <span>{{ app.app_date_start }}</span> до
                      <span>{{ app.app_date_end }}</span>
                    </p>
                  </div>
                </div>

                <div v-if="data.app_access.next_accesses" class="">
                  <div
                    v-for="(item, index) in data.app_access.next_accesses"
                    :key="index"
                    style="width: 100%"
                    class="d-flex text_block"
                  >
                    <div>
                      <p class="object__text-term">
                        {{ item.type_display }}:<span class="ml-3"
                          >{{ result_interval[index] }} дней</span
                        >
                      </p>
                    </div>
                    <div>
                      <p
                        v-if="result_interval[index] == 365"
                        class="object__text-date"
                      >
                        с
                        <span>{{ next_interval_start[index] }}</span>
                        до
                        <span>{{ next_interval_end[index] }}</span>
                      </p>

                      <p v-else class="object__text-date">
                        с
                        <span>{{ next_interval_start[index] }}</span>
                        до
                        <span>{{ next_interval_end[index] }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex-grow-1">
                <div
                  v-if="!isShowChangeTarif && !isShowContinue"
                  class="btns cards"
                  style="padding: 0"
                >
                  <button
                    v-if="
                      accessibleGroups &&
                        accessibleGroups.length > 0 &&
                        !!accessibleGroups.find(el => el.role === 110) &&
                        data.app_access_type != 'unpaid'
                    "
                    @click="isShowContinue = true"
                    class="btn btn-green btn-change auto-margin"
                  >
                    Продлить
                  </button>
                  <button
                    v-if="
                      inaccessibleGroups &&
                        inaccessibleGroups.length &&
                        data.app_access_type != 'unpaid'
                    "
                    @click="isShowChangeTarif = true"
                    class="btn btn-green btn-purse auto-margin"
                  >
                    {{
                      data.user_payment_date_end > 0
                        ? "Сменить тариф"
                        : "Активировать доступ"
                    }}
                  </button>
                </div>
              </div>
            </div>

            <div v-else class="object__block-rate-info"></div>

            <div v-if="data.controllers.length > 0" class="object__block-info ">
              <div class="object__title-info">
                <img
                  src="/img/icons/object__chip-icon.svg"
                  alt="object__chip-icon"
                  class="object__title-info-icon"
                />
                <p class="object__title-info-text">Контроллеров</p>
                <p class="object__title-info-number">
                  {{ data.controllers.length }}
                </p>
              </div>
              <div class="">
                <p
                  v-for="(controller, c_index) in data.controllers"
                  :key="c_index"
                  class="object__item-info"
                >
                  {{ controller ? controller : "" }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-ver-center " v-else>
          <img
            src="/img/icons/object__icon-phone.svg"
            alt="object__icon-phone"
            class="object__icon-phone"
            style="height: 65px; width: 50px"
          />
          <div
            class="flex-ver-center"
            style="width: 100% ;justify-content: space-between"
          >
            <div class="object__title-info-text">Доступ по приложению</div>

            <div
              v-if="!isShowChangeTarif && !isShowContinue"
              class="btns cards"
              style="padding: 0"
            >
              <button
                v-if="
                  accessibleGroups &&
                    accessibleGroups.length > 0 &&
                    !!accessibleGroups.find(el => el.role === 110)
                "
                @click="isShowContinue = true"
                class="btn btn-green btn-change auto-margin"
              >
                Продлить
              </button>
              <button
                v-if="
                  inaccessibleGroups &&
                    inaccessibleGroups.length &&
                    data.app_access_type != 'unpaid' &&
                    data.app_access
                "
                @click="isShowChangeTarif = true"
                class="btn btn-green btn-purse"
                style="margin-right: 20px"
              >
                {{ "Сменить тариф" }}
              </button>
              <button
                v-if="
                  inaccessibleGroups &&
                    inaccessibleGroups.length &&
                    data.app_access_type != 'unpaid'
                "
                @click="isShowChangeTarif = true"
                class="btn btn-green btn-purse auto-margin"
              >
                {{ "Активировать доступ" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="data.type == 'kindergarten'"
      class="object__item d-flex justify-end"
    >
      <div class="object__block-rate" style="width: 100%">
        <div
          class="object__block-card d-flex "
          style="flex-direction: column ;"
        >
          <div class="flex-ver-center" style="width: 100%">
            <img
              src="/img/icons/object__icon-doc.svg"
              alt="object__icon-doc"
              class="object__icon-phone"
              style="height: 65px; width: 50px"
            />

            <div
              class="flex-ver-center"
              style="width: 100% ; display: block"
              v-if="card && info.card_code"
            >
              <p class="object__text-application ">Доступ по карте</p>

              <div
                style="width: 100%"
                class="object__block-rate-info mt-0 flex-ver-center"
              >
                <div style="width: 100%" class="d-flex">
                  <div class="flex-column">
                    <p class="object__text-term">
                      Номер карты:
                      <span>{{ data.card_access.code || info.card_code }}</span>
                    </p>

                    <p class="object__text-term">
                      Действует :
                      <span class="ml-4">
                        {{ data.card_access.days_left }} дней</span
                      >
                    </p>
                  </div>

                  <p class="object__text-date" style="    align-self: end">
                    с
                    <span>{{ card.card_date_start }}</span>
                    до
                    <span>{{ card.card_date_end }}</span>
                  </p>
                </div>
                <div
                  v-if="!isShowChangeTarif && !isShowContinue"
                  class="cards btns"
                  style="padding: 0"
                >
                  <button
                    v-if="
                      data.card_access.card_code &&
                        // inaccessibleGroups.length > 0 &&
                        info.type === 'kindergarten'
                    "
                    @click="isCardShowContinue = true"
                    class="btn btn-green btn-purse"
                    style="margin-right: 20px"
                  >
                    Купить карту
                  </button>
                  <button
                    v-if="
                      info.type === 'kindergarten' &&
                        info.card_code === null &&
                        this.data.card_date_start == null
                    "
                    @click="(buyCardShowContinue = true), (clickObject = info)"
                    class="btn btn-green cardsYes"
                    style="margin-right: 20px ; max-width: 200px"
                  >
                    У меня есть карта (Вести номер карты)
                  </button>
                  <button
                    v-if="
                      info.card_code != null ||
                        (info.card_code != null && info.type === 'kindergarten')
                    "
                    @click="(lostCardShowContinue = true), (clickObject = info)"
                    class="btn btn_color_white"
                    style="margin-right: 20px; background-color: #FF6600 ; color: #FFFFFF"
                  >
                    Потеряли карту? Нажмите, чтобы заблокировать
                  </button>
                </div>
              </div>

              <div
                style="color: #EB5C6D"
                v-if="data.is_await_card"
                class="object__text-term mt-2"
              >
                Карта оплачена, получите ее в Детском саду
              </div>

              <div
                style="color: #EB5C6D"
                v-if="data.card_access && data.card_access.inactive_reason"
                class="object__text-term"
              >
                <p class="object__title-info-text mt-2">
                  КАРТА УТЕРЯНА ПОЛЬЗОВАТЕЛЕМ
                </p>
                <p
                  v-if="data.card_access.code"
                  class="object__text-term mt-2 mb-3"
                >
                  Номер карты: <span>{{ data.card_access.code }}</span>
                </p>
              </div>

              <div
                v-if="
                  data.controllers.length > 0 &&
                    card &&
                    card.card_days_left &&
                    data.card_code
                "
                class="object__block-info "
              >
                <div class="object__title-info">
                  <img
                    src="/img/icons/object__chip-icon.svg"
                    alt="object__chip-icon"
                    class="object__title-info-icon"
                  />
                  <p class="object__title-info-text">Контроллеров</p>
                  <p class="object__title-info-number">
                    {{ data.controllers.length }}
                  </p>
                </div>
                <div class="">
                  <p
                    v-for="(controller, c_index) in data.controllers"
                    :key="c_index"
                    class="object__item-info"
                  >
                    {{ controller ? controller : "" }}
                  </p>
                </div>
                <div class="object__text-term byNote">
                  <i>
                    Примечание: Для доступа на объект возможно использовать
                    только одну карту доступа</i
                  >
                </div>
              </div>
            </div>
            <div
              class="flex-ver-center"
              style="width: 100% ;justify-content: space-between ; display: flex"
              v-else
            >
              <div class="">
                <div class="object__title-info-text">Доступ по карте</div>

                <div
                  v-if="!isShowChangeTarif && !isShowContinue"
                  class="btns cards d-flex flex-column"
                  style="padding: 0 "
                >
                  <div class="" v-if="data.is_await_card">
                    <div style="color: #EB5C6D" class="object__text-term mt-2">
                      Карта оплачена, получите ее в Детском саду
                    </div>
                    <div class="object__text-term">
                      <i>
                        Примечание: Для доступа на объект возможно использовать
                        только одну карту доступа</i
                      >
                    </div>
                  </div>

                  <div
                    v-else-if="data?.card_access?.inactive_reason"
                    class="object__text-term note mt-4"
                  >
                    <i>
                      Примечание: Для доступа на объект возможно использовать
                      только одну карту доступа</i
                    >
                  </div>
                  <div v-else class="object__text-term note">
                    <i>
                      Примечание: Для доступа на объект возможно использовать
                      только одну карту доступа</i
                    >
                  </div>

                  <div
                    style="color: #EB5C6D"
                    v-if="data.card_access && data.card_access.inactive_reason"
                    class="object__text-term"
                  >
                    <p class="object__title-info-text mt-2">
                      КАРТА УТЕРЯНА ПОЛЬЗОВАТЕЛЕМ
                    </p>
                    <p
                      v-if="data.card_access.code"
                      class="object__text-term mb-3"
                    >
                      Номер карты: <span>{{ data.card_access.code }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="cards btns" style="padding: 0px">
                <button
                  v-if="
                    // inaccessibleGroups.length > 0 &&
                    !data.card_date_start &&
                      !data.card_date_end &&
                      !data.card_days_left &&
                      !data.card_access &&
                      info.type === 'kindergarten'
                  "
                  @click="isCardShowContinue = true"
                  class="btn btn-green btn-purse"
                >
                  Купить карту
                </button>
                <button
                  v-else-if="
                    // inaccessibleGroups.length > 0 &&

                    !data.card_code &&
                      data.card_access &&
                      !data.is_await_card &&
                      !data.card_access.inactive_reason &&
                      info.type === 'kindergarten'
                  "
                  @click="isCardShowContinue = true"
                  class="btn btn-green btn-purse"
                  style="margin-right: 20px"
                >
                  Купить карту
                </button>

                <button
                  v-else-if="
                    !data.card_access &&
                      !data.is_await_card &&
                      // inaccessibleGroups.length > 0 &&
                      info.type === 'kindergarten'
                  "
                  @click="isCardShowContinue = true"
                  class="btn btn-green btn-purse"
                  style="margin-right: 20px"
                >
                  Купить карту
                </button>
                <button
                  v-else-if="
                    !data.is_await_card &&
                      // inaccessibleGroups.length > 0 &&
                      info.type === 'kindergarten'
                  "
                  @click="isCardShowContinue = true"
                  class="btn btn-green btn-purse"
                  style="margin-right: 20px"
                >
                  Купить карту
                </button>

                <button
                  v-if="
                    info.type === 'kindergarten' &&
                      info.card_code === null &&
                      !data.card_date_start &&
                      !data.card_date_end &&
                      !data.card_days_left &&
                      !data.card_access
                  "
                  @click="(buyCardShowContinue = true), (clickObject = info)"
                  class="btn btn-green cardsYes"
                  style="max-width: 200px"
                >
                  У меня есть карта (Вести номер карты)
                </button>
                <button
                  v-else-if="
                    data.app_access_type != 'unpaid' &&
                      // inaccessibleGroups.length > 0 &&
                      !data.is_await_card &&
                      !data.card_code &&
                      data.card_access &&
                      !data.card_access.inactive_reason &&
                      info.type === 'kindergarten'
                  "
                  @click="(buyCardShowContinue = true), (clickObject = info)"
                  class="btn btn-green cardsYes"
                  style="margin-right: 20px ; max-width: 200px"
                >
                  У меня есть карта (Вести номер карты)
                </button>
                <button
                  v-else-if="
                    data.app_access_type != 'unpaid' &&
                      !data.card_access &&
                      !data.is_await_card &&
                      // inaccessibleGroups.length > 0 &&
                      info.type === 'kindergarten'
                  "
                  @click="(buyCardShowContinue = true), (clickObject = info)"
                  class="btn btn-green cardsYes"
                  style="margin-right: 20px ; max-width: 200px"
                >
                  У меня есть карта (Вести номер карты)
                </button>
                <button
                  v-else-if="
                    !data.card_access &&
                      !data.is_await_card &&
                      // inaccessibleGroups.length > 0 &&
                      info.type === 'kindergarten'
                  "
                  @click="(buyCardShowContinue = true), (clickObject = info)"
                  class="btn btn-green cardsYes"
                  style="margin-right: 20px ; max-width: 200px"
                >
                  У меня есть карта (Вести номер карты)
                </button>
                <button
                  v-else-if="
                    !data.is_await_card &&
                      // inaccessibleGroups.length > 0 &&
                      info.type === 'kindergarten'
                  "
                  @click="(buyCardShowContinue = true), (clickObject = info)"
                  class="btn btn-green cardsYes"
                  style="margin-right: 20px ; max-width: 200px"
                >
                  У меня есть карта (Вести номер карты)
                </button>
                <button
                  v-if="info.card_code != null && info.type === 'kindergarten'"
                  @click="(lostCardShowContinue = true), (clickObject = info)"
                  class="btn btn_color_white"
                  style="margin-right: 0; background-color: #FF6600 ; color: #FFFFFF"
                >
                  Потеряли карту? Нажмите, чтобы заблокировать
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-dialog v-model="buyCardShowContinue" max-width="450">
        <v-card class="pa-6">
          <div class="object__item">
            <div class="object__body flex justify-center ">
              <v-card-title class="justify-center" style="text-align:center"
                >Введите номер карты для получения доступа
              </v-card-title>
              <FormInputBlock class="mt-2">
                <ValidationInputField
                  placeholder="###.#####"
                  mask="###.#####"
                  v-model="cardNumber"
                  label="Номер карты"
                  validate-name="Номер карты"
                />
              </FormInputBlock>
            </div>
            <v-card-actions class="justify-center mt-4">
              <button
                :class="{ disabled: disabled }"
                @click.prevent="addExistingCard(cardNumber)"
                class="btn btn_color_green authoriz__btn mr-2"
              >
                Подтвердить
              </button>

              <button
                @click.prevent="buyCardShowContinue = false"
                class="btn btn_color_white"
              >
                Отмена
              </button>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="lostCardShowContinue"
        v-model="lostCardShowContinue"
        max-width="450"
      >
        <v-card class="pa-6">
          <div class="object__item">
            <div class="object__body flex justify-center ">
              <div class="justify-center" style="text-align:center">
                После блокировки, карта не восстанавливается. Вам необходимо
                будет купить новую карту.
              </div>
              <v-card-title class="justify-center" style="text-align:center"
                >Заблокировать карту № {{ clickObject.card_code }}?
              </v-card-title>
            </div>
            <v-card-actions class="justify-center mt-4">
              <button
                @click.prevent="lostCard()"
                class="btn btn_color_green authoriz__btn mr-2"
              >
                Да
              </button>
              <button
                @click.prevent="lostCardShowContinue = false"
                class="btn btn_color_white"
              >
                Отмена
              </button>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog v-model="isShowContinue" max-width="550">
        <v-card>
          <div class="object__item">
            <MyObjectsForm :data="data" :groups="accessibleGroups">
            </MyObjectsForm>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="inaccessibleGroups.length > 0"
        v-model="isCardShowContinue"
        max-width="650"
      >
        <v-card>
          <div class="object__item">
            <MyObjectsForm
              :data="data"
              :groups="inaccessibleGroups"
              type="card"
            >
            </MyObjectsForm>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-else v-model="isCardShowContinue" max-width="550">
        <v-card>
          <div class="object__item">
            <MyObjectsForm :data="data" :groups="accessibleGroups" type="card">
            </MyObjectsForm>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog v-model="isShowChangeTarif" max-width="550">
      <v-card>
        <div class="object__item">
          <MyObjectsForm :data="data" :groups="inaccessibleGroups">
          </MyObjectsForm>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isShowContinue" max-width="550">
      <v-card>
        <div class="object__item">
          <MyObjectsForm :data="data" :groups="accessibleGroups">
          </MyObjectsForm>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MyObjectsForm from "@/components/info/myObjects/MyObjectsForm";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";

import moment from "moment";
import {
  getObjectAccessibleGroupsByIdRequest,
  getObjectInaccessibleGroupsByIdRequest
} from "@/helpers/api/objects";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "MyObjectTarifInfo",
  components: {
    MyObjectsForm,
    FormInputBlock,
    ValidationInputField
  },
  props: {
    code: {
      type: String
    },
    data: {
      type: Object
    },
    object: {
      type: Object
    }
  },
  data: () => ({
    isShow: false,
    isCardShowContinue: false,
    isShowContinue: false,
    accessibleGroups: [],
    inaccessibleGroups: [],
    isShowChangeTarif: false,
    buyCardShowContinue: false, // покупка карты
    lostCardShowContinue: false, // карта утеряна

    disabled: true,
    cardNumber: null, // добавление существующей карты
    clickObject: null //объект детского садика по которому кликнули
  }),
  watch: {
    cardNumber: function(val) {
      if (val.length == 9) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    }
  },
  computed: {
    type() {
      let result = {
        class: "color_blue",
        icon: "/img/icons/object__icon-main.svg",
        title: dictionariesHelper.objectType[this.data.type]
      };

      switch (this.$props.data.type) {
        case "kindergarten":
          result.icon = "/img/icons/object__icon-pyramid.svg";
          result.class = "color_green";
          break;
      }

      return result;
    },
    info() {
      return this.$props.data;
    },
    availableAccesses() {
      let { available_accesses } = this.info;
      return available_accesses;
    },
    controllersPaidAccess() {
      let { controllers_paid_access } = this.info;
      return controllers_paid_access;
    },
    controllersGuestAccess() {
      let { controllers_guest_access } = this.info;
      return controllers_guest_access;
    },

    next_interval_start() {
      let arr = [];
      for (
        let i = 0;
        i < this.$props.data.app_access.next_accesses.length;
        i++
      ) {
        arr.push(
          this.formatDaysDate(
            this.$props.data.app_access.next_accesses[i].date_start
          )
        );
      }
      return arr;
    },
    next_interval_end() {
      let arr = [];
      for (
        let i = 0;
        i < this.$props.data.app_access.next_accesses.length;
        i++
      ) {
        arr.push(
          this.formatDaysDate(
            this.$props.data.app_access.next_accesses[i].date_end
          )
        );
      }
      return arr;
    },
    result_interval() {
      let arr = [];
      for (
        let i = 0;
        i < this.$props.data.app_access.next_accesses.length;
        i++
      ) {
        arr.push(
          moment(
            this.$props.data.app_access.next_accesses[i].date_end * 1000
          ).diff(
            moment(
              this.$props.data.app_access.next_accesses[i].date_start * 1000
            ),
            "day"
          )
        );
      }
      return arr;
    },
    freeTrial() {
      return this.$store.getters.getUserInfo.free_trial;
    },
    app() {
      if (this.$props.data.app_access) {
        let { date_start, date_end, days_left } = this.$props.data.app_access;
        if (date_start && date_end && days_left) {
          let app = {
            app_date_start: this.formatDaysDate(date_start),
            app_date_end: this.formatDaysDate(date_end),
            app_days_left: days_left,
            app_paid_days: moment(date_end * 1000).diff(
              moment(date_start * 1000),
              "day"
            ),
            app_used_days:
              moment(date_start * 1000).diff(moment(), "days") * -1,
            app_left_days: moment(date_end * 1000).diff(moment(), "days"),
            app_used_days_percent:
              moment().diff(moment(date_start * 1000), "days") /
              (moment(date_end * 1000).diff(moment(date_start * 1000), "days") /
                100),
            app_left_days_percent:
              moment(date_end * 1000).diff(moment(), "days") /
              (moment(date_end * 1000).diff(moment(date_start * 1000), "days") /
                100)
          };
          return app;
        }
        return date_start && date_end && days_left;
      } else return null;
    },
    card() {
      if (this.$props.data.card_access) {
        let { date_start, date_end, days_left } = this.$props.data.card_access;
        if (date_start && date_end && days_left) {
          let card = {
            card_date_start: this.formatDaysDate(date_start),
            card_date_end: this.formatDaysDate(date_end),
            card_days_left: days_left,
            card_paid_days: moment(date_end * 1000).diff(
              moment(date_start * 1000),
              "day"
            ),
            card_used_days:
              moment(date_start * 1000).diff(moment(), "days") * -1,
            card_left_days: moment(date_end * 1000).diff(moment(), "days"),
            card_used_days_percent:
              moment().diff(moment(date_start * 1000), "days") /
              (moment(date_end * 1000).diff(moment(date_end * 1000), "days") /
                100),
            card_left_days_percent:
              moment(date_end * 1000).diff(moment(), "days") /
              (moment(date_end * 1000).diff(moment(date_start * 1000), "days") /
                100)
          };
          return card;
        }
        return date_start && date_end && days_left;
      } else return null;
    }
  },
  mounted() {
    this.getAccessibleGroups(this.$props.data.id);
    this.getInaccessibleGroups(this.$props.data.id);
  },
  methods: {
    formatDaysDate(date) {
      if (date) {
        return moment(date * 1000).format("DD.MM.YYYY");
      }
    },

    daysLeft(date) {
      moment.locale("ru");
      if (moment(date, "X").diff(moment()) > 0)
        return moment(date, "X").fromNow(true);
      return null;
    },
    getAccessibleGroups(id) {
      getObjectAccessibleGroupsByIdRequest({
        id
      }).then(response => {
        this.accessibleGroups = response.data.results;
      });
    },
    getInaccessibleGroups(id) {
      getObjectInaccessibleGroupsByIdRequest({
        id
      }).then(response => {
        this.inaccessibleGroups = response.data.results;
      });
    },

    addExistingCard(number) {
      const objId = this.clickObject.id;
      this.$store
        .dispatch("addExistingCardByUser", { number, objId })
        .then(() => {
          this.$store.dispatch("fetchPageObjectExtendedList").finally(() => {});
          this.$store.dispatch(
            "successNotification",
            actionMessage.activated(successMessageDictionary.card)
          );
          this.buyCardShowContinue = false;
          this.$emit("reloadData");
        });
    },

    lostCard() {
      const cardNumber = this.info.card_code;
      const currentCardId =
        this.$store.getters.getCurrentUser?.cards.filter(
          el => el.code == cardNumber
        )[0].id || this.$store.getters.getCurrentUser.cards[0].id;
      this.$store.dispatch("lostCardUser", { id: currentCardId }).then(() => {
        this.$store.dispatch("fetchPageObjectExtendedList").finally(() => {});
        this.$store.dispatch(
          "successNotification",
          actionMessage.ban(successMessageDictionary.card)
        );
        this.lostCardShowContinue = false;
        this.$emit("reloadData");
      });
    }
  }
};
</script>

<style scoped>
.btn {
  min-width: 200px;
  max-width: 200px;
}

.text_block {
  display: flex;
  align-items: center;
}

.object__text-date {
  white-space: nowrap;
}
.object__item-info {
  white-space: nowrap;
}
.list__content-adding .object__point {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  padding: 5px 15px;
  text-overflow: ellipsis;
  display: inline;
  line-height: 1;
}

.list__content-adding .object__item.object__item-timeleft {
  padding-bottom: 70px;
}
.note {
  position: absolute;
  top: 70px;
}
.byNote {
  position: relative;
}

@media screen and (min-width: 651px) and (max-width: 993px) {
  .note {
    position: inherit;
  }
  .text_block {
    display: flex;
    align-items: flex-start;
  }
  .list__content-adding .btns .btn {
    max-width: 100%;
    min-width: 48%;
    margin-left: 0;
  }
  .cards {
    display: flex !important;
    flex-wrap: nowrap;
  }
  .flex-ver-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: block !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .auto-margin {
    width: 45% !important;
    margin-left: auto !important;
  }
  .btn:last-of-type {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 650px) {
  .note {
    position: inherit;
  }
  .byNote {
    position: inherit;
  }
  .flex-ver-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: block !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cardsYes {
    max-width: 100%;
    margin-left: 0;
  }
  .sbp {
    margin: 0 !important;
  }
  .text_block {
    display: block !important;
  }

  .object__text-term {
    margin-right: 0;
  }

  .list__content-adding .btns .btn {
    max-width: 100% !important;
    margin-left: 0;
    margin-right: 0px;
  }
}
</style>
