<template>
  <v-menu v-model="menu" :close-on-content-click="false" bottom left>
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on" v-bind:init="init">
        <v-btn @click="init" v-on="on">
          <v-icon>
            mdi-menu
          </v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-list class="headers-list search_value btn-configs_select-item">
        <v-list-item>
          <v-list-item-action>
            <v-checkbox v-model="selectAll" label="Выбрать все" />
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-for="head in preChange" :key="head.value">
          <v-list-item-action>
            <v-checkbox v-model="head.enable" :label="head.text" />
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="close" color="error" class="btn btn_color_white"
          >Отмена</v-btn
        >
        <v-btn @click="finish" class="btn btn_color_green">Готово</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep";
export default {
  name: "HeaderToggler",
  props: {
    enableHeaders: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      menu: false,
      preChange: []
    };
  },
  computed: {
    selectAll: {
      get() {
        return this.preChange.every(item => item.enable);
      },
      set(value) {
        this.preChange.forEach(item => {
          item.enable = value;
        });
      }
    }
  },
  methods: {
    init() {
      this.preChange = _cloneDeep(this.enableHeaders);
    },
    finish() {
      this.$emit("update:enable-headers", this.preChange);
      this.close();
    },
    close() {
      this.menu = false;
    }
  }
};
</script>

<style scoped>
.headers-list {
  overflow-y: auto;
  max-height: 300px;
}
</style>
