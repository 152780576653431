<template>
  <MainLayout>
    <template v-slot:navbar-title>Добавить</template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:manual_input>
        <CreateFormLayout :tabs="slideTabs">
          <template v-slot:title__text>
            Воспитанник
          </template>
          <template v-slot:title-flex-right>
            <div></div>
          </template>
          <template>
            <!--    Данные компании    -->
            <div>
              <FormCardTitle title="Данные воспитанника"></FormCardTitle>
              <FormInputBlock>
                <!--                <ValidationInputField-->
                <!--                  rules="required"-->
                <!--                  label="Фамилия"-->
                <!--                  v-model="lastName"-->
                <!--                  validate-name="фамилия"-->
                <!--                ></ValidationInputField>-->
                <!--                <ValidationInputField-->
                <!--                  rules="required"-->
                <!--                  label="Имя"-->
                <!--                  v-model="name"-->
                <!--                  validate-name="имя"-->
                <!--                ></ValidationInputField>-->
                <!--                <ValidationInputField-->
                <!--                  label="Отчество"-->
                <!--                  v-model="middleName"-->
                <!--                  validate-name="отчество"-->
                <!--                ></ValidationInputField>-->
                <ValidationInputField
                  rules="required|min:1|max:40"
                  label="Табельный номер"
                  v-model="personalNumber"
                  validate-name="табельный номер"
                />
                <ValidationInputField
                  label="Воспитанник"
                  v-model="name"
                  validate-name="воспитанник"
                  :rules="'min:1|max:40'"
                ></ValidationInputField>
                <ValidationAutocompleteField
                  :search-function="userGroupChildrenSearch"
                  rules="required"
                  label="Название группы"
                  :disabled="!!childrenGroupsId"
                  v-model="childrenGroup"
                  validate-name="номер группы"
                />
              </FormInputBlock>
            </div>
            <!--    Данные компании    -->
          </template>
          <template>
            <!--    Данные компании    -->
            <!--            <div>-->
            <!--              <FormCardTitle title="Информация об опекунах"></FormCardTitle>-->
            <!--              <FormCardTitle-->
            <!--                title="Выбрать опекуна из существующих пользователей"-->
            <!--              ></FormCardTitle>-->
            <!--              <FormInputBlock>-->
            <!--                <ValidationAutocompleteField-->
            <!--                  class="validation-select-field"-->
            <!--                  :search-function="parentsSearch"-->
            <!--                  label="Опекуны"-->
            <!--                  v-model="parents"-->
            <!--                  validate-name="опекуны"-->
            <!--                  :rules="parentsList.length > 0 ? '' : 'required'"-->
            <!--                />-->
            <!--                <v-btn-->
            <!--                  outlined-->
            <!--                  class="btn btn-green"-->
            <!--                  @click="addExistParent(parents)"-->
            <!--                  >Добавить-->
            <!--                </v-btn>-->
            <!--              </FormInputBlock>-->
            <!--            </div>-->
            <!-- <div> -->
            <FormCardTitle title="Информация об опекунах"></FormCardTitle>

            <ValidationObserver ref="formParent" v-slot="{ validate }" disabled>
              <FormInputBlock>
                <ValidationInputField
                  label="Телефон"
                  rules="phone|required"
                  mask="+7 (9##) ###-##-##"
                  v-model="phoneParents"
                  validate-name="Телефон"
                ></ValidationInputField>

                <ValidationInputField
                  label="Пользователь (необязательно)"
                  validate-name="пользователь"
                  :rules="'min:1|max:40'"
                  v-model="user"
                  :disabled="!disabledEdit"
                />
                <ValidationInputField
                  label="Комментарий (необязательно)"
                  validate-name="комментарий"
                  :rules="'min:1|max:40'"
                  v-model="comments"
                  :disabled="!disabledEdit"
                />
                <v-btn
                  outlined
                  class="btn btn-green"
                  @click="validate().then(res => (res ? addNewParent() : null))"
                  >Добавить
                </v-btn>
              </FormInputBlock>
            </ValidationObserver>
          </template>
          <!--    Родители    -->
          <div>
            <FormCardTitle title="Опекуны"></FormCardTitle>
            <table
              class="table table_overflow_hidden table-physical_cards ym-hide-content"
            >
              <thead>
                <tr>
                  <th>Телефон</th>
                  <th>Пользователь</th>
                  <th>Комментарий</th>
                  <th>Удаление</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(parent, index) in parentsList"
                  class="item"
                  :key="index"
                >
                  <td>{{ phone[index] }}</td>

                  <td>
                    {{ parent.user }}
                  </td>
                  <td>
                    {{ parent.comments }}
                  </td>
                  <td class="btn-delete-wrap">
                    <button
                      @click.stop="deleteParent(parent.id)"
                      class="form__btn-delete"
                    ></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--    /Родители    -->
          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
              type="submit"
              @click="handleSubmit(createChildren)"
              class="btn btn-green"
              :disabled="isDisabled"
            >
              Сохранить
            </v-btn>
            <!-- @click="handleSubmit(createChildren)" -->
          </template>
        </CreateFormLayout>
      </template>
      <template v-slot:add_fail>
        <CreateFormLayout>
          <template v-slot:title__text>
            Массовое добавление воспитанников
          </template>

          <template v-slot:title-flex-right>
            <div></div>
          </template>

          <div class="instruction">
            <p class="instruction__title">Правила заполнения файла:</p>
            <div class="instruction__description">
              <ol>
                <li>
                  Каждая строка должна содержать табельный номер, ФИО
                  воспитанника, телефон опекуна, ФИО пользователя, комментарий.
                </li>
                <li>
                  Номер телефона должен соответствовать формату 79876543210. Не
                  должно быть пробелов, скобок и других знаков.
                </li>

                <li>
                  Допустимо добавление данных не более чем 10 опекунов для
                  одного воспитанника.
                </li>
                <li>Не допускаются пустые строки.</li>
                <li>
                  Пример заполнения
                  <a href="/img/images/documentations/upload_children.png"
                    >по ссылке.</a
                  >
                </li>
              </ol>
            </div>
          </div>

          <template>
            <!--    Данные компании    -->
            <div>
              <FormInputBlock>
                <ValidationFileField
                  :rules="'required'"
                  v-model="file"
                  accept=".xlsx"
                  label="Выбрать файл"
                  validate-name="Выбрать файл"
                />
                <ValidationAutocompleteField
                  :search-function="userGroupChildrenSearch"
                  rules="required"
                  label="Название группы"
                  :disabled="!!childrenGroupsId"
                  v-model="childrenGroup"
                  validate-name="номер группы"
                />
              </FormInputBlock>
            </div>
            <!--    Данные компании    -->
          </template>
          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
              type="submit"
              @click="handleSubmit(uploadChildrens)"
              class="btn btn-green"
            >
              Сохранить
            </v-btn>
          </template>
        </CreateFormLayout>
      </template>
    </SlideLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationFileField from "@/components/ValidationField/ValidationFileField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import {
  getChildrenGroupByIdRequest,
  getChildrenGroupsRequest
} from "@/helpers/api/childrenGroup";
import // getAllUsersClearRequest,
// getUserByIdRequest,
// getUsersClearRequest
"@/helpers/api/user";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import _ from "lodash";
import { ValidationObserver } from "vee-validate";
import phoneFilter from "@/helpers/filters/phoneFilter";

export default {
  name: "ChildrenCreateForm",
  props: {
    kindergartenId: {
      type: [String, Number]
    },
    childrenGroupsId: {
      type: [String, Number]
    }
  },
  components: {
    ValidationAutocompleteField,
    ValidationInputField,
    ValidationFileField,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    MainLayout,
    SlideLayout,
    ValidationObserver
  },
  data() {
    return {
      isDisabled: false,
      loading: false,
      number: null,
      groupNumber: null,
      currentUser: null,
      disabledEdit: false,

      userGroupChildrenSearch: async value => {
        const id = this.$store.getters.getCurrentOrganization;
        return (
          await getChildrenGroupsRequest({
            query: {
              kindergarten_org: this.kindergartenId || id,
              search: value
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id,
          group: el.group
        }));
      },
      // parentsSearch: async value => {
      //   return (
      //     await getUsersClearRequest({
      //       query: {
      //         search: value.replace(/[^\d+а-яА-ЯёЁ ]/g, "").slice(0, 13)
      //       }
      //     })
      //   ).data.results.map(el => ({
      //     text:
      //       this.$store.getters.getCurrentRole == 70
      //         ? phoneFilter.execute(el.phone_number) +
      //           " " +
      //           el.last_name +
      //           " " +
      //           el.first_name +
      //           " " +
      //           el.middle_name
      //         : phoneFilter.execute(el.phone_number),
      //     value: el.id
      //   }));
      // },
      parentsList: [], //список родителей
      tempId: 0, // временный id для родителей который нужно создать ( он отрицательный, для того чтобы различать существующих и нет)
      file: null,
      slideTabs: [
        {
          text: "Добавить вручную",
          value: "manual_input",
          icon: null,
          count: null
        },
        {
          text: "Загрузить файл XLSX",
          value: "add_fail",
          icon: null,
          count: null
        }
      ]
    };
  },
  beforeDestroy() {
    this.$store.commit("clearCreateFormChildren", {
      fieldName: "createChildren"
    });
    this.$store.commit("clearCreateParentsForm", {
      listName: "createParentsForm"
    });
  },
  methods: {
    dadataField() {
      if (this.currentUser.length == 0) {
        this.$store.commit("clearCreateParentsForm", {
          listName: "createParentsForm"
        });
        this.phoneParents = this.number;
        this.userGroups = this.groupNumber;
        this.disabledEdit = true;
      } else {
        this.disabledEdit = false;
        this.user = this.currentUser[0].additional_fields[0].value;
        this.comments = this.currentUser[0].additional_fields[1].value;
      }
    },
    createChildren() {
      if (this.parentsList.length <= 0) {
        this.$store.dispatch(
          "warningNotification",
          "Добавьте хотя бы одного родителя!"
        );
      } else {
        this.isDisabled = false;

        this.loading = true;
        this.$store
          .dispatch("createChildren", { parentsList: this.parentsList })
          .then(childId => {
            if (!this.$store.getters.getPageQuery) {
              this.$router.replace(`childrenGroups/${childId}`);
            } else {
              this.$router.replace(`children/${childId}`);
            }

            return;
          })
          .then(() => {
            this.isDisabled = false;
            this.$store.dispatch(
              "successNotification",
              actionMessage.add(successMessageDictionary.children)
            );
          })

          .finally(() => {
            this.loading = false;
          });
      }
    },

    deleteParent(id) {
      this.parentsList = this.parentsList.filter(el => el.id != id);
    },

    // async addExistParent(parentId) {
    //   const parentInfo = (await getUserByIdRequest({ id: parentId })).data;
    //
    //   //добавляем только уникальные номера
    //   const tempParentsList = this.parentsList;
    //   tempParentsList.push(parentInfo);
    //   this.parentsList = _.uniqBy(tempParentsList, "phone_number");
    //
    //   //обнуляем поля
    //   this.parents = null;
    //   this.$refs.formParent.reset();
    //   this.$nextTick(() => {
    //     this.phoneParents = null;
    //     this.user = null;
    //     this.comments = null;
    //     // this.middleNameParents = null;
    //   });
    // },

    addNewParent() {
      this.tempId = this.tempId - 1;
      const parentInfo = {
        user: this.user,
        comments: this.comments,
        // middle_name: this.middleNameParents,
        phone_number: this.phoneParents
          .replace(/[^\d+а-яА-ЯёЁ]/g, "")
          .slice(0, 13),
        id: this.tempId
      };

      //добавляем только уникальные номера
      const tempParentsList = this.parentsList;
      tempParentsList.push(parentInfo);
      this.parentsList = _.uniqBy(tempParentsList, "phone_number");

      //обнуляем поля
      this.$refs.formParent.reset();
      this.$nextTick(() => {
        this.phoneParents = null;
        this.user = null;
        this.comments = null;
        // this.middleNameParents = null;
      });
    },

    // fullName(last_name, first_name, middle_name) {
    //   return `${last_name || "-"} ${first_name || ""} ${middle_name || ""}`;
    // },

    uploadChildrens() {
      this.loading = true;
      this.$store.commit("setRouterQuery", {
        routes: this.$route.params
      });
      this.$store
        .dispatch("uploadChildrens", this.file)
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.upload(successMessageDictionary.childrens)
          );
        })
        .catch(error => {
          this.$store.commit("SET_ERROR_NOTIFICATION", {
            errors: [
              {
                field_verbose: "Ошибка",
                message: `${error.response.data.detail}`
              }
            ]
          });
        })
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    phoneParents: async function(val) {
      let data = {
        phone_number: this.phoneParents
      };
      if (val?.length == 18) {
        this.$store.dispatch("fetchKindergartenUserInfo", { data }).then(() => {
          this.currentUser = this.$store.getters.getCreateCurrentUserKindergartens;
          this.dadataField();
        });
      }
    }
  },
  computed: {
    phone() {
      let arr = [];
      for (let i = 0; i < this.parentsList.length; i++) {
        arr.push(phoneFilter.execute(this?.parentsList[i].phone_number));
      }

      return arr;
    },
    // lastName: {
    //   get() {
    //     return this.$store.getters.getCreateChildren.last_name;
    //   },
    //   set(newValue) {
    //     this.$store.commit("setChildrenCreateForm", {
    //       listName: "last_name",
    //       value: newValue
    //     });
    //   }
    // },
    // middleName: {
    //   get() {
    //     return this.$store.getters.getCreateChildren.middle_name;
    //   },
    //   set(newValue) {
    //     this.$store.commit("setChildrenCreateForm", {
    //       listName: "middle_name",
    //       value: newValue
    //     });
    //   }
    // },
    // name: {
    //   get() {
    //     return this.$store.getters.getCreateChildren.first_name;
    //   },
    //   set(newValue) {
    //     this.$store.commit("setChildrenCreateForm", {
    //       listName: "first_name",
    //       value: newValue
    //     });
    //   }
    // },

    personalNumber: {
      get() {
        return this.$store.getters.getCreateChildren.number;
      },
      set(newValue) {
        this.$store.commit("setChildrenCreateForm", {
          listName: "number",
          value: newValue
        });
      }
    },
    name: {
      get() {
        return this.$store.getters.getCreateChildren.name;
      },
      set(newValue) {
        this.$store.commit("setChildrenCreateForm", {
          listName: "name",
          value: newValue
        });
      }
    },
    childrenGroup: {
      get() {
        return this.$store.getters.getCreateChildren.children_group;
      },
      set(newValue) {
        this.$store.commit("setChildrenCreateForm", {
          listName: "children_group",
          value: newValue
        });
      }
    },
    parents: {
      get() {
        return this.$store.getters.getCreateChildren.parents;
      },
      set(newValue) {
        this.$store.commit("setChildrenCreateForm", {
          listName: "parents",
          value: newValue
        });
      }
    },
    phoneParents: {
      get() {
        return this.$store.getters.getCreateParentsForm.phone_number;
      },
      set(newValue) {
        this.number = newValue;
        this.$store.commit("setParentsCreateForm", {
          fieldName: "phone_number",
          value: newValue
        });
      }
    },
    // selectedId: {
    //   get() {
    //     return this.$store.getters.getCreateParentsForm.selectedId;
    //   },
    //   set(newValue) {
    //     this.$store.commit("setParentsCreateForm", {
    //       fieldName: "selectedId",
    //       value: newValue
    //     });
    //   }
    // },
    userGroupsParents: {
      get() {
        return this.$store.getters.getCreateParentsForm.group;
      },
      set(newValue) {
        this.groupNumber = newValue;

        this.$store.commit("setParentsCreateForm", {
          fieldName: "group",
          value: newValue
        });
      }
    },
    user: {
      get() {
        return this.$store.getters.getCreateParentsForm.user;
      },
      set(newValue) {
        this.$store.commit("setParentsCreateForm", {
          fieldName: "user",
          value: newValue
        });
      }
    },
    comments: {
      get() {
        return this.$store.getters.getCreateParentsForm.comments;
      },
      set(newValue) {
        this.$store.commit("setParentsCreateForm", {
          fieldName: "comments",
          value: newValue
        });
      }
    }
    // lastNameParents: {
    //   get() {
    //     return this.$store.getters.getCreateParentsForm.last_name;
    //   },
    //   set(newValue) {
    //     this.$store.commit("setParentsCreateForm", {
    //       fieldName: "last_name",
    //       value: newValue
    //     });
    //   }
    // }
  },
  created() {
    if (this.childrenGroupsId) {
      getChildrenGroupByIdRequest({ id: Number(this.childrenGroupsId) }).then(
        response => {
          this.childrenGroup = response.data.id;
          this.userGroupsParents = response.data?.group_details?.id;
        }
      );
    }
  }
};
</script>

<style scoped></style>
